import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'

// styles
import './bannerHome.scss'

const BannerApp = (props) => {

  return <>
    <div className='container'>
      <Link to={`${props.link}`} target='_blank' rel='noreferrer'>
        <div className='row'>
          <div className='col-12 position-relative custom-padding'>
            <GatsbyImage
              image={props.image}
              alt="Banner Home Desktop"
              className='d-none d-md-block w-100'
            />
            <GatsbyImage
              image={props.imageMobile}
              alt="Banner Home Mobile"
              className='d-block d-md-none w-100'
            />
          </div>
        </div>
      </Link>
    </div>

  </>;
}
export default BannerApp
