/* eslint-disable react/display-name */
import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { GatsbyImage } from 'gatsby-plugin-image'

const BannerHome = ({ images }) => {

  return <>
    <Carousel showArrows={true} showThumbs={false} showIndicators={false} showStatus={false} infiniteLoop={true}
      interval={5000} autoPlay={true}>
      {
        images.map(image => {
          return (
            <div>
              <a href={image.node.link}>
                <GatsbyImage
                  image={image.node.imagemDesktop.localFile.childImageSharp.gatsbyImageData}
                  alt="Banner Home Desktop"
                  className='d-none d-md-block w-100'
                />
              </a>
              <a href={image.link}>
                <GatsbyImage
                  image={image.node.imagemMobile.localFile.childImageSharp.gatsbyImageData}
                  alt="Banner Home Mobile"
                  className='d-block d-md-none w-100'
                />
              </a>
            </div>
          )
        })
      }
    </Carousel>
  </>
}

export default BannerHome