import React from 'react'
import Swiper from 'react-id-swiper'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

// styles
import './instagram.scss'

const params = {
  slidesPerView: 1.5,
  spaceBetween: 10,
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  breakpoints: {
    576: {
      slidesPerView: 4,
      spaceBetween: 10
    }
  }
}

const Instagram = () => {
  const data = useStaticQuery(
    graphql`{
  allInstaPost(limit: 6) {
    edges {
      node {
        permalink
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 270, height: 270, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}
`
  )

  return <>
    {data.allInstaPost.edges.length > 0 &&
      <div className='container custom-p'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='pb-3 text-dark'>#Spani no Insta</h2>
          </div>
        </div>
        <div id='carosel-instagram_settings'>
          <Swiper {...params}>
            {
              data.allInstaPost.edges.map((insta, index) => {
                return (
                  <a href={insta.node.permalink} target="_blank" without="true" rel="noreferrer" key={index}>
                    <GatsbyImage
                      image={insta.node.localFile.childImageSharp.gatsbyImageData}
                      className='rounded mb-4 w-100 h-100'
                      alt="imagens do instagram" />
                  </a>
                )
              })
            }
          </Swiper>
        </div>
      </div>
    }
  </>;
}
export default Instagram
