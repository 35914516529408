import React, { useState, useRef } from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
// import Img from 'gatsby-image'

// components
import Layout from '../components/Layout/index'
import Banner from '../components/Home/bannerHome'
import BannerHome from '../components/bannersHome/bannerHome'
import CardOferta from '../components/cardOfertas/cardOfertas'
import BannerCard from '../components/bannerCard/bannerCard'
import SectionBlogPost from '../components/SectionBlogPost/sectionBlogPost'
import Instagram from '../components/Instagram/instagram'

// styles
import './styles/index.scss'

const IndexPage = ({ data, pageContext }) => {
  const [currentMarket, setCurrentMarket] = useState(typeof window !== 'undefined' ? JSON.parse(window.localStorage.getItem('currentMarket')) : null)
  const modalRef = useRef()

  console.log()
  return (
    <Layout homeCallback={setCurrentMarket} ref={modalRef}>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pt" />
        <title>Spani Atacadista | Home</title>
        <meta name="title" content='Spani Atacadista | Home' />
        <meta name="description" content='O Spani Atacadista atua desde 2003, e possui atualmente 26 lojas no estado de São Paulo e também no estado do Rio de Janeiro.' />
        <meta name="keywords" content='spani, spani atacadista, lojas' />
        <meta name="author" content="Spani" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content='Spani Atacadista | Home' />
        <meta property="og:type" content="article" />
        <meta property="og:url" content='https://www.spani.com.br/' />
        <meta property="og:description" content='O Spani Atacadista atua desde 2003, e possui atualmente 26 lojas no estado de São Paulo e também no estado do Rio de Janeiro.' />
        <meta
          property="og:image"
          content='https://www.spani.com.br/card-spani.png'
        />
      </Helmet>
      <div className="btn-compra-online">
        <a
          href="https://www.spanionline.com.br/"
          target="_blank"
          rel="noopener noreferrer"
          className="btn-link"
        >
          COMPRE ONLINE
        </a>
      </div>
      <Banner images={data.allContentfulBannerHome.edges} />
      {/* <div className='d-none d-xl-block position-absolute w-100'>
        <div className='d-flex justify-content-between'>
          <Img fixed={data.balao1.childImageSharp.fixed} />
          <Img fixed={data.balao2.childImageSharp.fixed} />
        </div>
      </div> */}
      <div className={`${data.infoCards.edges.length !== 0 ? 'pt-4 pb-4' : 'pb-4'}`}>
        {data.infoCards.edges.length !== 0 &&
          <CardOferta modal={modalRef} current={currentMarket} info={data.infoCards}></CardOferta>
        }
      </div>
      <SectionBlogPost></SectionBlogPost>
      <div className='container pt-4'>
        <div className='row'>
          <div className='col-12 col-sm-6 custom-padding'>
            <BannerCard
              image={data.contentfulHome.bannerapp.localFile.childImageSharp.gatsbyImageData}
              imageMobile={data.contentfulHome.bannerappMobile.localFile.childImageSharp.gatsbyImageData}
              link={data.contentfulHome.bannerappLink}
              alt='Banner App Spani'
            />
          </div>
          <div className='col-12 col-sm-6 pt-4 pt-sm-0 custom-padding'>
            <BannerCard
              image={data.contentfulHome.bannerqualifica.localFile.childImageSharp.gatsbyImageData}
              imageMobile={data.contentfulHome.bannerqualificaMobile.localFile.childImageSharp.gatsbyImageData}
              link={data.contentfulHome.bannerqualificaLink}
              alt='Banner Spani Qualifica'
            />
          </div>
        </div>
      </div>
      <div className='container pt-4'>
        <div className='row'>
          <div className='col-12 col-sm-6 custom-padding'>
            <BannerCard
              image={data.contentfulHome.bannerlojas.localFile.childImageSharp.gatsbyImageData}
              imageMobile={data.contentfulHome.bannerlojasMobile.localFile.childImageSharp.gatsbyImageData}
              link={data.contentfulHome.bannerlojasLink}
              alt='Banner Lojas'
            />
          </div>
          <div className='col-12 col-sm-6 pt-4 pt-sm-0 custom-padding'>
            <BannerCard
              image={data.contentfulHome.bannerdelivery.localFile.childImageSharp.gatsbyImageData}
              imageMobile={data.contentfulHome.bannerdeliveryMobile.localFile.childImageSharp.gatsbyImageData}
              link={data.contentfulHome.bannerdeliveryLink}
              alt='Banner Delivery'
            />
          </div>
        </div>
      </div>
      <div className='mt-4'>
        {/* <Link to='https://oferta.vocedm.com.br/cartaospani'> */}
        <BannerHome
          image={data.contentfulHome.bannercartao.localFile.childImageSharp.gatsbyImageData}
          imageMobile={data.contentfulHome.bannercartaoMobile.localFile.childImageSharp.gatsbyImageData}
          link={data.contentfulHome.bannercartaoLink}
          alt='Banner Cartao Spani'
          target="_blank"
          rel='noreferrer'
        />
        {/* </Link> */}
      </div>

      <div className='mt-4'>
        <Instagram></Instagram>
      </div>
    </Layout>
  );
}

export default IndexPage

export const query = graphql`query ($date: Date) {
  allContentfulBannerHome(
    filter: {dataInicio: {lte: $date}, dataFinal: {gte: $date}}
    sort: {fields: ordem, order: ASC}
  ) {
    edges {
      node {
        titulo
        dataInicio
        dataFinal
        link
        imagemDesktop {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        imagemMobile {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, width: 414, height: 400, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
  infoCards: allContentfulOfertas(
    filter: {dataInicio: {lte: $date}, dataFim: {gte: $date}}
  ) {
    edges {
      node {
        nome
        dataInicio
        dataFim
        ofertasCards {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  contentfulHome {
    bannerapp {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    bannerappMobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    bannerappLink
    bannerqualifica {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    bannerqualificaMobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    bannerqualificaLink
    bannercartao {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    bannercartaoMobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    bannercartaoLink
    bannerdelivery {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    bannerdeliveryMobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    bannerdeliveryLink
    bannerlojas {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    bannerlojasMobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    bannerlojasLink
  }
  balao1: file(relativePath: {eq: "stamp-corinthians/balao1.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 215, height: 550, layout: FIXED)
    }
  }
  balao2: file(relativePath: {eq: "stamp-corinthians/balao2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 215, height: 550, layout: FIXED)
    }
  }
}
`
