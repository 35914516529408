import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'

const BannerCard = (props) => {

  return <>
    <Link to={props.link}>
      {/* <GatsbyImage image={sources} alt={props.alt} /> */}
      <GatsbyImage
        image={props.image}
        alt="Banner Home Desktop"
        className='d-none d-md-block w-100'
      />
      <GatsbyImage
        image={props.imageMobile}
        alt="Banner Home Mobile"
        className='d-block d-md-none w-100'
      />
    </Link>
  </>
}
export default BannerCard
