import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Swiper from 'react-id-swiper'

// styles
import './cardOfertas.scss'

// images
import locationPin from '../../images/icons/header/location-pin.svg'

const CardOferta = (props) => {
  const [currentMarket] = useState(typeof window !== 'undefined' ? JSON.parse(window.localStorage.getItem('currentMarket')) : null)
  const params = {
    slidesPerView: 1.5,
    spaceBetween: 10,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    breakpoints: {
      576: {
        slidesPerView: 4,
        spaceBetween: 10
      }
    }
  }

  function getData () {
    const date = new Date(props.info.edges[0].node.dataFim)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const formatedDate = `${(day < 10 ? '0' : '') + day}/${(month < 10 ? '0' : '') + month}/${date.getFullYear()}`
    return formatedDate
  }

  return <>
    <div className='container'>
      <div className='row pb-3'>
        <h2 className='col-12 text-capitalize text-dark'>Aproveite as ofertas</h2>
        <div className='col-12 d-block d-lg-flex justify-content-between'>
          <div className='col-12 col-lg-6 p-0'>
            <p className='font-openSans-italic valid-date'>
              Ofertas válidas até {props.info && getData()} ou até durarem os estoques
            </p>
          </div>
          <div className="d-flex justify-content-lg-end justify-content-start align-items-center col-12 col-lg-6 pt-3 p-0 pt-sm-0">
            <img src={locationPin} alt="pin de localização da loja atual" className="mr-2" width="13" height="17" />
            { props.current &&
                <div>
                  <span className='font-weight-bold font-location' id='currentMarket2'>{props.current ? props.current.nome : 'Selecione uma Loja'}&nbsp;&nbsp; </span>
                  <span className='font-weight-bold font-location'>
                    |&nbsp;&nbsp;<u onClick={() => props.modal.current.modalHandler()}>Ver em outra loja</u>
                  </span>
                </div>
            }
            { props.current == null &&
                <span className='font-weight-bold font-location'>
                  <u onClick={() => props.modal.current.modalHandler()}>Selecione uma loja</u>
                </span>
            }
          </div>
        </div>
      </div>
      <div id='carosel-container_settings'>
        <Swiper {...params}>
          {props.info.edges[0].node.ofertasCards.map((imagens, index) => {
            return (
              <div className="px-2 py-3" key={index}>
                <GatsbyImage
                  image={imagens.localFile.childImageSharp.gatsbyImageData}
                  alt='Imagens de ofertas Home'
                  style={{ boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.1)' }}
                  className='mb-4' />
              </div>
            )
          })}
        </Swiper>
      </div>
      <div className='mt-3'>
        <div className='row'>
          <div className='col-12 d-flex justify-content-center'>
            {
              currentMarket
                ? (
                  <Link to={`/lojas/${currentMarket.slug}/`} className='btn btn-home text-uppercase font-openSans-semibold d-flex justify-content-center align-items-center'>
                    veja mais ofertas
                  </Link>
                  )
                : (
                  <Link to='/lojas/' className='btn btn-home text-uppercase font-openSans-semibold d-flex justify-content-center align-items-center'>
                    veja mais ofertas
                  </Link>
                  )
            }
          </div>
        </div>
      </div>
    </div>

  </>
}
export default CardOferta
